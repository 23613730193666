import styled from '@emotion/styled'
import React from 'react'
import { ElevatedCardWrapper } from './Elevated'
import { colors, dimensions } from '../../styles/variables'

export const StyledBoxWrapper = styled(ElevatedCardWrapper)`
  .box {
    box-shadow: ${props => (props.recommended ? '0 15px 30px 0 rgba(0, 0, 0, 0.15)' : 'none')};
    height: 100%;
  }
`

export const SubscriptionCard = styled(({ ...props }) => <StyledBoxWrapper {...props} />)`
  margin-top: ${props => (props.recommended ? '-1.2rem' : '')};
  height: ${props => (props.recommended ? 'calc(100% + 2.4rem)' : '')};

  .header {
    margin-top: ${props => (props.recommended ? '2.4rem' : '0')};
    margin-bottom: 1.2rem;
    text-transform: uppercase;
  }

  .recommended {
    position: relative;

    :before {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      position: absolute;
      content: 'Best value: Save 20%!';
      top: 0;
      left: -1px;
      color: ${colors.white};
      font-weight: ${dimensions.fontWeight.semibold};
      width: calc(100% + 2px);
      padding: 0.2rem;
      background-color: #ff9100;
    }
  }

  .caption {
    display: flex;
    align-items: center;
    margin-top: 1.2rem;
    margin-bottom: 2.4rem;
    font-size: 1.2rem;
    font-weight: ${dimensions.fontWeight.semibold};
  }

  .price {
    color: ${colors.brand.main};
  }

  hr {
    width: 3.6rem;
    height: 2px;
  }

  .button {
    margin-top: auto;
  }
`
