import * as React from 'react'
import styled from '@emotion/styled'
import { Box, Column, Section } from 'rbx'
import { css } from '@emotion/core'
import { HelpCircle } from 'styled-icons/feather/HelpCircle'
import { Headphones } from 'styled-icons/feather/Headphones'
import { Link } from 'gatsby'
import Page from '../../components/Page'
import IndexLayout from '../../layouts'
import StyledContainer from '../../components/Container'
import { dimensions } from '../../styles/variables'
import { StyledButtonLink } from '../../components/Button'
import { StyledBoxWrapper } from '../../components/Card'
import { Panel } from '../../components/Panel'

const PageHeader = styled(Section)`
  text-align: center;

  p {
    font-size: ${dimensions.fontSize.xl}px;
    font-weight: ${dimensions.fontWeight.light};
  }
`

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <StyledContainer>
        <PageHeader>
          <h2>Contact</h2>
          <p>Start a conversation with us today about synchronizing your orders across platforms.</p>
        </PageHeader>

        <Column.Group
          centered
          css={css`
            text-align: center;
          `}
        >
          <Column size="one-third">
            <StyledBoxWrapper>
              <Box>
                <HelpCircle size="72" />
                <h4>Help & Support</h4>
                <p>Tragedy at the wormhole that is when evil phenomenans tremble.</p>
                <StyledButtonLink fullwidth color="primary">
                  Get support
                </StyledButtonLink>
              </Box>
            </StyledBoxWrapper>
          </Column>

          <Column size="one-third">
            <StyledBoxWrapper>
              <Box as={Link} to="/support">
                <Headphones size="72" />
                <h4>Sales</h4>
                <p>The ego has vision, but not everyone hears it.</p>
                <StyledButtonLink fullwidth color="primary">
                  Contact sales
                </StyledButtonLink>
              </Box>
            </StyledBoxWrapper>
          </Column>
        </Column.Group>
      </StyledContainer>

      <Panel>
        <StyledContainer>
          <Column.Group centered gapSize={8}>
            <Column size="one-third">
              <h5>Company Headquarters</h5>
              <p>Meltwater Incubator, Ambassadorial Enclave, 19 Banana St, East Legon, Accra, Ghana</p>
            </Column>

            <Column size="one-third">
              <h5>Join our Reddit channel</h5>
              <p>
                If you have technical questions, chat live with developers in <b>#maverickz</b> on Reddit.
              </p>
            </Column>
          </Column.Group>
        </StyledContainer>
      </Panel>
    </Page>
  </IndexLayout>
)

export default IndexPage
